exports.components = {
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-modalidades-js": () => import("./../../../src/pages/modalidades.js" /* webpackChunkName: "component---src-pages-modalidades-js" */),
  "component---src-pages-redirecionamento-processoegarantismo-js": () => import("./../../../src/pages/redirecionamento/processoegarantismo.js" /* webpackChunkName: "component---src-pages-redirecionamento-processoegarantismo-js" */),
  "component---src-pages-redirecionamento-producao-de-conteudo-js": () => import("./../../../src/pages/redirecionamento/producaoDeConteudo.js" /* webpackChunkName: "component---src-pages-redirecionamento-producao-de-conteudo-js" */),
  "component---src-pages-redirecionamento-terapiaintensiva-js": () => import("./../../../src/pages/redirecionamento/terapiaintensiva.js" /* webpackChunkName: "component---src-pages-redirecionamento-terapiaintensiva-js" */),
  "component---src-pages-registreinteresse-js": () => import("./../../../src/pages/registreinteresse.js" /* webpackChunkName: "component---src-pages-registreinteresse-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-curso-home-js": () => import("./../../../src/templates/curso/home.js" /* webpackChunkName: "component---src-templates-curso-home-js" */),
  "component---src-templates-cursos-js": () => import("./../../../src/templates/cursos.js" /* webpackChunkName: "component---src-templates-cursos-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-para-empresas-js": () => import("./../../../src/templates/para-empresas.js" /* webpackChunkName: "component---src-templates-para-empresas-js" */)
}

